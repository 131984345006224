import { combineReducers } from '@reduxjs/toolkit';
import conversationSliceForExternalChat from './twyn/episodes/conversationForExternalChat/conversationSliceForExternalChat';

const rootReducer = combineReducers({
  conversationSliceForExternalChat,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
