export interface Episode {
    twyn_id: string;
    episode_id: string;
    start_clip: string;
    clips: Record<string, EpisodeClip>;
    varyEntryPoints: EntryPoint[];
}
export interface EpisodeClip {
    next: Record<string, string>[];
    entry_triggers: EntryTrigger[];
    options: ClipOptions;
    active_video: ActiveVideo;
    enable_global_triggers: boolean | null | undefined;
    subtitles: string;
    transcript: string;
    intro_completed_checkpoint: boolean;
    shaka_link: any;
    default_input: any;
    max_number_views: any;
    hideThumbnail: boolean;
    interruptTime: any;
    idle_clips: Record<string, string>[];
}

export interface ActiveVideo {
    video_id: string;
    video_url: string;
    uploaded_time: string;
    scaling_behaviour_landscape: string;
    scaling_behaviour_portrait: string;
}

export interface EntryTrigger {
    Trigger: string;
    Priority: number | null | undefined;
}

export interface ClipOptions {
    uuid: string;
    recordStyle: string;
    title: string;
    option_records: OptionRecord[];
}

export interface OptionRecord {
    title: string;
    bff_link: BffLink;
}

export interface BffLink {
    twyn_id: string;
    episode_id: string;
    start_clip_label: string;
}

export const defaultEpisode: Episode = {
    twyn_id: '',
    episode_id: '',
    start_clip: '',
    clips: {},
    varyEntryPoints: [],
};

export interface EntryPoint {
    entryClip: string;
    visit: number | null
}

export const defaultActiveVideo: ActiveVideo = {
    video_id: '',
    video_url: '',
    uploaded_time: '',
    scaling_behaviour_landscape: '',
    scaling_behaviour_portrait: ''
};

export const defaultBffLink: BffLink = {
    twyn_id: '',
    episode_id: '',
    start_clip_label: ''
}

export const defaultOptionRecord: OptionRecord = {
    title: '',
    bff_link: defaultBffLink
}

export const defaultOptions: ClipOptions = {
    uuid: '',
    title: '',
    recordStyle: '',
    option_records: [defaultOptionRecord]
}

export const defaultEpisodeClip: EpisodeClip = {
    next: [],
    entry_triggers: [],
    options: defaultOptions,
    active_video: defaultActiveVideo,
    enable_global_triggers: true,
    subtitles: '',
    transcript: '',
    intro_completed_checkpoint: true,
    shaka_link: {},
    default_input: {},
    max_number_views: 5,
    hideThumbnail: false,
    interruptTime: '',
    idle_clips: []
};

export function getEpisodeDefaultStartClipLabel(episode: Episode): string {
    return episode.clips.GLOBAL?.next?.find(
        (tr: Record<string, string>) => tr.trigger === 'START',
    )?.name ?? episode.start_clip ?? '' ;
}

export function getStartClipLabelBasedOnEpisodeVisits(episode: Episode, episodeVisitsCount: number): string {
    const defaultStartClipLabel = getEpisodeDefaultStartClipLabel(episode);
    const allEntryPoints = episode.varyEntryPoints ?? [];
    try {
        if (allEntryPoints.length === 0) return defaultStartClipLabel;

        const defaultEntryPoint = allEntryPoints.find((ep) => !ep.visit);
        let eligableEntryPoints = allEntryPoints.filter(
            (ep) => ep.visit && ep.visit >= episodeVisitsCount,
        );
        if (eligableEntryPoints.length === 0 && defaultEntryPoint)
            return defaultEntryPoint.entryClip;

        if (eligableEntryPoints.length === 0 && !defaultEntryPoint)
            return allEntryPoints.sort((a, b) => b.visit! - a.visit!)[0].entryClip;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return eligableEntryPoints.sort((a, b) => a.visit! - b.visit!)[0].entryClip;
    } catch (err) {
        return defaultStartClipLabel;
    }
}

