import {
  ApplicationInsights,
  DistributedTracingModes,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { instrumentationKey } from './config';
import history from './history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: `InstrumentationKey=${instrumentationKey};IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/`,
    enableAutoRouteTracking: false,
    disableFetchTracking: false,
    distributedTracingMode: DistributedTracingModes.W3C,
    disableCorrelationHeaders: false,
    disableAjaxTracking: false,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: false,
    enableResponseHeaderTracking: false,
    correlationHeaderExcludedDomains: [
      'twynmediaservicesdev-euno.streaming.media.azure.net',
      'mytwynmediaservices-euno.streaming.media.azure.net',
      'mytwyn-global.azurefd.net',
    ],
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});

export function enableAnalytics() {
  appInsights.loadAppInsights();
}

export function logErrors(
  messageText: string,
  telemetryData: Record<string, string>,
) {
  appInsights.trackTrace({
    message: messageText,
    severityLevel: SeverityLevel.Error,
    properties: telemetryData,
  });
}

export function logEmail(emailData: string) {
  appInsights.trackTrace({
    message: 'Twyn Chat User Email Entered',
    severityLevel: SeverityLevel.Information,
    properties: { email: emailData },
  });
}

export function logAnalytics(telemetryData: Record<string, string>) {
  appInsights.trackTrace({
    message: 'Twyn Chat For Externals',
    severityLevel: SeverityLevel.Information,
    properties: telemetryData,
  });
}
