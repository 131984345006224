/* eslint-disable camelcase */
import { BlobServiceClient } from '@azure/storage-blob';
import { DateTime } from 'luxon';
import { ConnectionsForExternalChat } from './conversationSliceForExternalChat';
import { EpisodePrompts } from './schema/EpisodePrompts';
import { LLMRequestBody } from './schema/LLM';
import { Episode } from './schema/Episode';
import {
  StoryBlokClipOption,
  StoryBlokOptions,
  StoryBlokStoryResponse,
  SubItem,
  defaultStoryBlokOptions,
} from './schema/StoryBlok';

export interface ResourceLinkForExternalChat {
  rel: string;
  href: string;
}

export interface ResourceForExternalChat {
  _links: ResourceLinkForExternalChat[];
}

export interface ClipForExternalChat {
  transcript: string;
  video_url?: string;
  clip_label?: string;
}

export interface UtteranceResourceForExternalChat
  extends ResourceForExternalChat {
  utterance: ClipForExternalChat;
  idle: ClipForExternalChat;
  connections: ConnectionsForExternalChat;
}

const externalChatStorageContainerUrl =
  'https://twynappsdata.blob.core.windows.net/chat-for-externals';

export async function fetchEpisodePrompts(
  twynId: string,
  version: string | null | undefined,
): Promise<Map<string, EpisodePrompts>> {
  const versionUse =
    version === null || version === undefined ? '' : `_${version}`;
  const promptsBlobName = `${twynId}_prompts_tmp${versionUse}.json`;

  const response = await fetch(
    `${externalChatStorageContainerUrl}/${promptsBlobName}`,
    {
      method: 'GET',
    },
  );
  if (response.ok) {
    return new Map<string, EpisodePrompts>(
      Object.entries(await response.json()),
    );
  }
  throw Error(`Failed to get tokens prompts file  ${promptsBlobName}`);
}

export async function fetchEpisodes(
  twynId: string,
  version: string | null | undefined,
): Promise<Episode[]> {
  const versionUse =
    version === null || version === undefined ? '' : `_${version}`;

  const episodesBlobName = `${twynId}_episodes${versionUse}.json`;

  const response = await fetch(
    `${externalChatStorageContainerUrl}/${episodesBlobName}`,
    {
      method: 'GET',
    },
  );
  if (response.ok) {
    return (await response.json()) as Episode[];
  }
  throw Error(`Failed to fetch the episodes json file ${episodesBlobName}`);
}

export async function getLLMResponse(
  utterance: string,
  clipLabel: string,
  prompts: EpisodePrompts,
): Promise<string> {
  const url = `https://llm-prompts-validator.azurewebsites.net/api/getLLMResponse`;

  const promptStart = prompts.context[clipLabel].prompt_start;
  const promptEnd = prompts.context[clipLabel].prompt_end;

  const prompt = `${promptStart} ${utterance} ${promptEnd} `;

  const timeBeforeRequest = DateTime.utc().toMillis();

  const requestBody: LLMRequestBody = {
    prompt,
  };
  const response = await fetch(`${url}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseTime = DateTime.utc().toMillis() - timeBeforeRequest;

  if (response.ok) {
    const llmResponse = await response.json();
    return llmResponse;
  }
  throw Error(`Failed to get LLM response for user utterance ${utterance}`);
}

export async function getTextGenerationLLMResponse(
  utterance: string,
  generationPrompt: string,
): Promise<string> {
  const url = `https://llm-prompts-validator.azurewebsites.net/api/getLLMText`;

  const prompt = `${generationPrompt} ${utterance} `;

  const timeBeforeRequest = DateTime.utc().toMillis();

  const requestBody: LLMRequestBody = {
    prompt,
  };
  const response = await fetch(`${url}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseTime = DateTime.utc().toMillis() - timeBeforeRequest;

  if (response.ok) {
    const llmResponse = await response.json();
    return llmResponse;
  }
  throw Error(`Failed to get LLM response for user utterance ${utterance}`);
}

export async function downloadJsonDataFromAzureStorage(
  blobName: string,
): Promise<any> {
  const accountUrl = 'https://twynappsdata.blob.core.windows.net';
  const containerName = 'chat-for-externals';
  const blobServiceClient = new BlobServiceClient(accountUrl);

  const containerClient = blobServiceClient.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(blobName);

  const downloadBlockBlobResponse = await blobClient.download();
  const downloaded = await blobToString(
    await downloadBlockBlobResponse.blobBody,
  );

  return JSON.parse(downloaded);
}

export async function fetchClipOptionsByUUID(
  storyUUID: string,
): Promise<StoryBlokOptions> {
  if (!storyUUID || storyUUID.trim() === '') return defaultStoryBlokOptions;
  const url = `https://api.storyblok.com/v2/cdn/stories/${storyUUID}?token=L61ucaXVs4RhaM84HUjUxAtt&find_by=uuid`;
  const response = await fetch(`${url}`, {
    method: 'GET',
  });

  if (response.ok) {
    const storyData = (await response.json()) as StoryBlokStoryResponse;

    const optionsDescription =
      storyData.story.content.Modal[0]?.modalTop[0]?.subitems?.[0]?.text ??
      'N/A';
    const options = retrieveClipOptions(
      storyData.story.content.Modal[0]?.modalTop[0]?.subitems,
    ).concat(
      retrieveClipOptions(
        storyData.story.content.Modal[0]?.modalBottom[0]?.subitems,
      ),
    );
    return {
      description: optionsDescription,
      options,
    };
  }
  throw Error('Failed to get clip options from StoryBlok');
}

async function blobToString(blob: Blob | undefined): Promise<string> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      resolve(ev.target.result);
    };
    fileReader.onerror = reject;
    if (blob) {
      fileReader.readAsBinaryString(blob);
    }
  });
}

function retrieveClipOptions(subitems: SubItem[]): StoryBlokClipOption[] {
  if (!subitems || subitems.length < 1) return [];
  return subitems
    ?.filter(
      (item) =>
        item.bff_link.length > 0 &&
        item.bff_link[0].resource_type === 'conversations' &&
        item.bff_link[0].start_clip_label.trim() !== '',
    )
    ?.map((item) => ({
      title: item.title,
      clipLabel: item.bff_link[0].start_clip_label,
    }));
}

function numberGeneratorForClipWithRudeTrigger(i: number): string {
  return `'${i}'`;
}

function numberGeneratorForClipsWithoutRudeTrigger(i: number): string {
  return `'${i + 1}'`;
}

function optionsRules(
  options: string,
  prefix: string,
  suffix: string,
  hasRudeTrigger: boolean,
): string {
  const optionsCount = options.split('-').length - (hasRudeTrigger ? 0 : 1);
  const numbers = [...Array(optionsCount).keys()]
    .map((i) =>
      hasRudeTrigger
        ? numberGeneratorForClipWithRudeTrigger(i)
        : numberGeneratorForClipsWithoutRudeTrigger(i),
    )
    .join(', ');

  const numbersFormatted = replaceLastOccurrence(numbers, ',', ' or');
  return `${prefix}${numbersFormatted}${suffix}`;
}

function replaceLastOccurrence(
  originalString: string,
  searchString: string,
  replacement: string,
): string {
  const lastIndex = originalString.lastIndexOf(searchString);
  if (lastIndex === -1) {
    return originalString;
  }
  const prefix = originalString.substring(0, lastIndex);
  const suffix = originalString.substring(lastIndex + searchString.length);
  return `${prefix}${replacement}${suffix}`;
}
