import React from 'react';
import { Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { hasContent } from '../../../HelperFunctions/strings';
import { UtteranceBubbleColour } from './schema/Utterance';
import { isProduction } from '../../../config';
import { theme } from '../../../theme';
import { ReactComponent as AmplifyIcon } from '../../../images/svg/logo-white.svg';
import { ReactComponent as CustomerIcon } from '../../../images/svg/Customer-image.svg';

interface UtteranceForExternalChatProps {
  speaker: string;
  transcript: string;
  speakerBubbleColour: UtteranceBubbleColour;
  transcribedClipLabel: string;
}

export default function UtteranceBubbleForExternalChat({
  speaker,
  transcript,
  speakerBubbleColour,
  transcribedClipLabel,
}: UtteranceForExternalChatProps) {
  function isValidUrl(transcriptValue: string) {
    try {
      // eslint-disable-next-line
      new URL(transcriptValue);
      return true;
    } catch (err) {
      return false;
    }
  }
  const isValidURl = isValidUrl(transcript);
  const colour = speaker === 'User' ? 'primary' : speakerBubbleColour;
  const speakerNameLabelId =
    speaker === 'User' ? uuid() : `speakerName_${transcribedClipLabel}`;
  const visibleClipLabel =
    (!isProduction || !hasContent(transcript)) &&
    hasContent(transcribedClipLabel)
      ? `  ${transcribedClipLabel} `
      : '';
  const justifyContendValue = speaker === 'User' ? 'flex-end' : 'flex-start';
  const colspacing = speaker === 'User' ? 4 : 0;
  const paddingLeft = speaker === 'User' ? 1.5 : 0;

  return (
    <>
      <Box display="block" sx={{ marginRight: 5 }}>
        <Grid
          container
          direction="row"
          columnSpacing={colspacing}
          justifyContent={justifyContendValue}
          sx={{
            color: `${colour}.contrastText`,
            borderRadius: 2,
            marginBottom: 0.5,
            marginTop: 2,
            marginLeft: 0.5,
          }}
        >
          {speaker !== 'User' ? (
            <Grid item xs={0.5}>
              <Chip
                id={speakerNameLabelId}
                avatar={
                  speaker === 'User' ? (
                    <Avatar>
                      <CustomerIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ width: 40, height: 40 }}>
                      <AmplifyIcon />
                    </Avatar>
                  )
                }
                size="medium"
                sx={{
                  backgroundColor: 'transparent',
                }}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={9}>
            <Grid
              container
              direction="row"
              sx={{
                backgroundColor: '#1E1E1E',
                color: `${colour}.contrastText`,
                borderRadius: 5,
                borderTopLeftRadius: speaker === 'User' ? '5' : '0',
                borderTopRightRadius: speaker === 'User' ? '0' : '5',
                padding: 1.1,
                border:
                  speaker === 'User'
                    ? 'none'
                    : `1px solid ${theme.palette.primary.main}`,
              }}
            >
              <Grid item xs={12}>
                <Typography sx={{ color: 'white', paddingLeft }}>
                  {isValidURl === true ? (
                    <div>
                      {' '}
                      <a
                        href={transcript}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        Watch the video.
                      </a>{' '}
                      When you have finished watching, come back to this window.{' '}
                    </div>
                  ) : (
                    transcript
                  )}
                  {visibleClipLabel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {speaker === 'User' ? (
            <Grid item xs={0.5}>
              <Chip
                id={speakerNameLabelId}
                avatar={
                  speaker === 'User' ? (
                    <Avatar>
                      <CustomerIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ width: 40, height: 40 }}>
                      <AmplifyIcon />
                    </Avatar>
                  )
                }
                size="medium"
                sx={{
                  backgroundColor: 'transparent',
                }}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
}
