export function isNullOrWhitespace(input?: string | null) {
  return (
    typeof input === 'undefined' ||
    input == null ||
    input.replace(/\s/g, '').length < 1
  );
}

export function hasContent(input?: string | null): input is string {
  return !isNullOrWhitespace(input);
}

export function contentOrUndefined(input?: string | null): string | undefined {
  return hasContent(input) ? input : undefined;
}
