import { createTheme, Theme } from '@mui/material';

const themeMode = 'dark';
const fontBody = 'Lato';
const fontBodyBold = 'Lato';
export const theme: Theme = createTheme({
  palette: {
    mode: themeMode,
    background: {
      paper: 'black',
      default: 'black',
    },
    primary: {
      main: '#4FE99A',
      light: '#4FE99A',
    },
    secondary: {
      main: '#E4E9F5',
      light: '#E4E9F5',
    },
    info: {
      main: '#6DD4E3',
      light: '#6DD4E3',
    },
    success: {
      main: '#7FE541',
      light: '#7FE541',
    },
  },
  typography: {
    h1: {
      color: 'white',
      fontFamily: fontBody,
      fontWeight: 400,
    },
    h2: {
      color: 'white',
      fontFamily: fontBody,
      fontWeight: 400,
    },
    h3: {
      color: 'white',
      fontFamily: fontBody,
      fontWeight: 400,
    },
    h4: {
      color: 'white',
      fontFamily: fontBody,
      fontWeight: 400,
    },
    h5: {
      color: 'white',
      fontFamily: fontBody,
      fontWeight: 800,
    },
    body1: {
      fontFamily: fontBody,
    },
    body2: {
      fontFamily: fontBodyBold,
      fontWeight: 700,
    },
  },
});
