const appName = 'TwynChatForExternals;';
const visitsCount = 'visits_count';

export function getEpisodeVisitsCount(episodeName: string): number {
  return Number.parseInt(
    localStorage.getItem(episodeStorageKey(episodeName)) ?? '0',
    10,
  );
}

export function setEpisodeVisitsCount(episodeName: string): void {
  localStorage.setItem(
    episodeStorageKey(episodeName),
    (getEpisodeVisitsCount(episodeName) + 1).toString(),
  );
}

export function resetEpisodeVisitsCount(episodeName: string): void {
  localStorage.removeItem(episodeStorageKey(episodeName));
}

function episodeStorageKey(episodeName: string): string {
  return `${appName}_${episodeName}_${visitsCount}`;
}
