export interface EpisodePrompts {
    context: Record<string, ContextEntry>;
    continuation_mappings: Record<string, Record<string, string>>;
    NON_LINEAR_NAVIGATION: Record<string, NonLinearNavigtion>;
}
export interface ContextEntry {
    prompt_start: string;
    prompt_end: string;
}

export interface NonLinearNavigtion {
    exit_list: string[],
    handler_transcript: string,
    exit_prompt: string,
}

export const  defaultEpisodePrompts: EpisodePrompts = {
    context: {},
    continuation_mappings: {},
    NON_LINEAR_NAVIGATION: {}
};

export const defaultEpisodePromptMap = new Map<string, EpisodePrompts>([['' , defaultEpisodePrompts]]);