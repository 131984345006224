import React, { useEffect, useRef } from 'react';
import { Avatar, Chip, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { RootState } from '../../../rootReducer';
import UtteranceBubbleForExternalChat from './UtteranceBubbleForExternalChat';
import { UtteranceForExternalChat } from './conversationSliceForExternalChat';
import { UtteranceBubbleColour } from './schema/Utterance';
import TypingDots from '../../../images/gif/typing-dots.gif';
import { hasContent } from '../../../HelperFunctions/strings';
import { ReactComponent as AmplifyIcon } from '../../../images/svg/logo-white.svg';

interface UtterancesPropsForExternalChat {
  utterances: UtteranceForExternalChat[];
  utteranceBubbleColours: Record<string, UtteranceBubbleColour>;
  twynReplyInProgress: boolean;
  currentUtteranceSpeakerName: string;
}

function UtterancesForExternalChat({
  utterances,
  utteranceBubbleColours,
  twynReplyInProgress,
  currentUtteranceSpeakerName,
}: UtterancesPropsForExternalChat) {
  const messagesBoundaryRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // messagesBoundaryRef?.current?.scrollIntoView(true);
    document.documentElement.scrollTop =
      document.getElementById('messagesBoundary')?.offsetTop ?? 0;

    document.getElementById('userInput')?.focus();
  }, [utterances, currentUtteranceSpeakerName]);

  return (
    <>
      <Grid container direction="column">
        {utterances.map((utterance) => (
          <Grid item key={utterance.id}>
            <Grid
              container
              direction="row"
              justifyContent={
                utterance.speaker === 'User' ? 'flex-end' : 'flex-start'
              }
            >
              <UtteranceBubbleForExternalChat
                speaker={utterance.speaker}
                transcript={utterance.transcript}
                speakerBubbleColour={
                  utteranceBubbleColours[utterance.speaker] ?? 'primary'
                }
                transcribedClipLabel={
                  utterance.speaker === 'User' ? '' : utterance.clipLabel ?? ''
                }
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container direction="row" marginBottom={0}>
        {twynReplyInProgress && (
          <Grid container direction="row" alignItems="start" spacing={0}>
            {hasContent(currentUtteranceSpeakerName) && (
              <Chip
                avatar={
                  <Avatar
                    sx={{ width: 40, height: 40, backgroundColor: 'primary' }}
                  >
                    <AmplifyIcon />
                  </Avatar>
                }
                size="medium"
                sx={{
                  backgroundColor: 'transparent',
                  height: 'auto',
                }}
              />
            )}
            <Grid item xs={0.7} style={{ marginTop: 0 }}>
              <Grid
                container
                direction="row"
                sx={{
                  backgroundColor: '#1E1E1E',
                  color: 'primary.contrastText',
                  borderRadius: 5,
                  borderTopRightRadius: 18,
                  borderTopLeftRadius: 0,
                  p: 1.1,
                  position: 'relative',
                  overflow: 'hidden',
                  height: '45px',
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: 'white',
                      textAlign: 'center',
                      position: 'relative',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <img
                      src={TypingDots}
                      alt="Typing..."
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: '45px',
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        id="messagesBoundary"
        direction="row"
        ref={messagesBoundaryRef}
        marginBottom={1}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  twynReplyInProgress: state.conversationSliceForExternalChat.isTwynReplying,
  utterances: state.conversationSliceForExternalChat.utterances,
  utteranceBubbleColours:
    state.conversationSliceForExternalChat.utteranceBubbleColours,
  currentUtteranceSpeakerName:
    state.conversationSliceForExternalChat.currentUtteranceSpeakerName,
});

export default connect(mapStateToProps)(UtterancesForExternalChat);
