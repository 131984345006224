export interface StoryBlokStoryResponse {
    story: Story;
    cv: number;
    rels: any[];
    links: any[];
}

export interface Story {
    name: string;
    created_at: string;
    published_at: string;
    id: number;
    uuid: string;
    content: Content;
    slug: string;
    full_slug: string;
    sort_by_date: any;
    position: number;
    tag_list: any[];
    is_startpage: boolean;
    parent_id: number;
    meta_data: any;
    group_id: string;
    first_published_at: string;
    release_id: any;
    lang: string;
    path: any;
    alternates: any[];
    default_full_slug: any;
    translated_slugs: any;
}

export interface Content {
    _uid: string;
    Modal: Modal[];
    component: string;
}

export interface Modal {
    _uid: string;
    modalTop: ModalTop[];
    component: string;
    borderColor: string;
    modalBottom: ModalBottom[];
    closureButton: boolean;
    closeButtonColor: string;
}

export interface ModalTop {
    _uid: string;
    Notes: string;
    subitems: SubItem[];
    component: string;
    background: Background[];
}

export interface Background {
    _uid: string;
    fill: string;
    colors: Color[];
    component: string;
}

export interface Color {
    _uid: string;
    color: string;
    component: string;
}

export interface ModalBottom {
    _uid: string;
    Notes: string;
    subitems: SubItem[];
    component: string;
    background: any[];
}

export interface SubItem {
    _uid: string;
    scale: string;
    title: string;
    layout: string;
    states: any[];
    actions: any[];
    bff_link: BffLink[];
    imageUrl: ImageUrl;
    lockable: boolean;
    alignment: string;
    component: string;
    fullWidth: boolean;
    lineLimit: string;
    marginTop: string;
    text: string;
    textColor: string;
    textStyle: string;
    imageItems: any[];
    itemLockID: string;
    buttonColor: string;
    buttonStyle: string;
    analyticsTag: string;
    marginBottom: string;
    appsFlyerEvent: string;
    pageCollection: any[];
}

export interface BffLink {
    url: Url;
    _uid: string;
    modal: any[];
    twyn_id: string;
    settings: Setting[];
    component: string;
    page_name: string;
    episode_id: string;
    product_id: string;
    route_type: string;
    story_uuid: string;
    multilingual: boolean;
    resource_type: string;
    start_clip_label: string;
    vary_entry_point: boolean;
}

export interface Url {
    id: string;
    url: string;
    linktype: string;
    fieldtype: string;
    cached_url: string;
}

export interface Setting {
    _uid: string;
    component: string;
    closure_type: string;
    microphone_type: string;
    interaction_mode: string;
    continuation_type: string;
}

export interface ImageUrl {
    id: any;
    alt: any;
    name: string;
    focus: any;
    title: any;
    filename: string;
    copyright: any;
    fieldtype: string;
}

export interface StoryBlokOptions {
    description: string;
    options: StoryBlokClipOption[];
}

export interface StoryBlokClipOption {
    title: string;
    clipLabel: string;
}

export const defaultStoryBlokOptions: StoryBlokOptions = {
    description: 'N/A',
    options: [],
}
