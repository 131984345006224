export const environmentName = [
  'chat.themessiverse.com',
  'chat.twyndesigner.com',
].includes(window.location.hostname)
  ? 'prod'
  : 'nonprod';

export const isProduction = environmentName === 'prod';

export const storageAccount = isProduction
  ? 'mytwynmediaservices'
  : 'twynmediaservicesdev';

export const envSuffix = isProduction ? '' : '-test';

export const instrumentationKey = isProduction
  ? '12276d14-b6c1-4739-b3e1-0c47f5238b7b'
  : '05f0e331-6abb-4fcb-9792-1329e290d2a5';

export const clientId = isProduction
  ? 'f13d3b51-a5b2-43d7-b890-09148c8191dc'
  : '0357806a-aeeb-4e43-a2da-7d8df81ef5fd';

export const tenantId = '2a18e756-8381-47f9-afc3-a6b76a65e6f9';
export const authority = `https://login.microsoftonline.com/${tenantId}`;

const externalChatStorageContainerUrl =
  'https://twynappsdata.blob.core.windows.net/chat-for-externals';

const chatBlobName = `chat_config.json`;
const convHistory: { clipLabel: string; utterance: string }[] = [];
const jumpFlagArray: { jumpFrom: string; jumpTo: string }[] = [];

function getJSONFromStorage(blobName: string): Record<string, string>[] {
  const request = new XMLHttpRequest();
  request.open('GET', `${externalChatStorageContainerUrl}/${blobName}`, false); // `false` makes the request synchronous
  request.send(null);
  if (request.status !== 200) {
    // handle an error here
    throw Error(`Failed to get file  ${blobName}`);
  }
  const data = JSON.parse(request.responseText);
  return data;
}
function emptyHistory() {
  convHistory.length = 0;
}
function setHistory(clipLabel: string, utterance: string) {
  convHistory.push({ clipLabel, utterance });
}

function emptyJumps() {
  jumpFlagArray.length = 0;
}
function addToJumpArray(jumpFrom: string, jumpTo: string) {
  jumpFlagArray.push({ jumpFrom, jumpTo });
}
function getLastJump() {
  return jumpFlagArray.pop();
}
function getFirstJump() {
  return jumpFlagArray.shift();
}
export const parameterLookupArray: Record<string, string>[] =
  getJSONFromStorage(chatBlobName);
export {
  convHistory,
  emptyHistory,
  setHistory,
  jumpFlagArray,
  emptyJumps,
  addToJumpArray,
  getLastJump,
  getFirstJump,
};
