import * as React from 'react';

import { parameterLookupArray } from './config';
import ConversationForExternalChat from './twyn/episodes/conversationForExternalChat/ConversationForExternalChat';

function Home() {
  const searchParams = new URLSearchParams(window.location.search);
  const version = searchParams.get('version')?.toLowerCase();
  const conversationParams = parameterLookupArray.find(
    (params) =>
      params.id.toLowerCase() === searchParams.get('id')?.toLowerCase(),
  );

  return (
    <ConversationForExternalChat
      twynId={conversationParams?.twynId}
      episodeId={conversationParams?.episodeId}
      startClip={conversationParams?.startClip}
      version={version}
    />
  );
}

export default Home;
