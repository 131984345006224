import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Button,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import {
  CurrentClip,
  handleClipOptionClicked,
  reply,
  start,
} from './conversationSliceForExternalChat';
import { RootState } from '../../../rootReducer';
import { EpisodePrompts } from './schema/EpisodePrompts';
import { Episode } from './schema/Episode';
import { fetchClipOptionsByUUID } from './ExternalChatService';
import { defaultStoryBlokOptions } from './schema/StoryBlok';
import { isProduction } from '../../../config';
import { ReactComponent as SendIconInactive } from '../../../images/svg/send-button.svg';
import { ReactComponent as SendIconActive } from '../../../images/svg/send-button-active.svg';
import { logEmail } from '../../../analytics';

interface ReplyPropsForExternalChat {
  conversationUUID: string;
  conversationInitiated: boolean;
  currentClip: CurrentClip;
  currentEpisode: Episode;
  currentEpisodePrompts: EpisodePrompts;
  twynReplyInProgress: boolean;
}

export function ReplyForExternalChat({
  conversationUUID,
  conversationInitiated,
  currentClip,
  currentEpisode,
  currentEpisodePrompts,
  twynReplyInProgress,
}: ReplyPropsForExternalChat) {
  const dispatch = useDispatch();
  const [transcript, setTranscript] = useState('');
  const [email, setEmail] = useState('');
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [clipOptionsFromStoryBlok, setClipOptionsFromStoryBlok] = useState(
    defaultStoryBlokOptions,
  );
  const [isEndOfConversationClip, setIsEndOfConversationClip] = useState(false);
  const [hasEnteredEmail, setHasEnteredEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [didEnterBadEmail, setDidEnterBadEmail] = useState(false);
  const currentClipOptionUUID: string = currentClip?.clip.options?.uuid ?? '';

  function validateEmail(emailValue: string): boolean {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue);
  }

  function focusTextField() {
    const userInputTextField: HTMLElement | null =
      document.getElementById('userInput');
    if (userInputTextField) {
      userInputTextField.focus();
    }
  }

  useEffect(() => {
    (async () => {
      const currentClipOptions = await fetchClipOptionsByUUID(
        currentClipOptionUUID,
      );
      setClipOptionsFromStoryBlok(currentClipOptions);
      setIsEndOfConversationClip(
        currentClip?.clip?.next?.length === 0 &&
          currentClipOptions.options.length === 0,
      );
    })();
  }, [currentClipOptionUUID, currentClip?.clip?.next?.length]);

  return (
    <>
      {conversationInitiated && !isEndOfConversationClip && (
        <form
          onSubmit={async (event) => {
            setTranscript('');
            event.preventDefault();
            if (!processingSubmit) {
              setProcessingSubmit(true);
              try {
                await dispatch(
                  reply(
                    conversationUUID,
                    transcript,
                    currentClip.clipLabel,
                    currentEpisode,
                    currentEpisodePrompts,
                  ),
                );

                setTimeout(() => {
                  focusTextField();
                }, 500);
              } finally {
                setProcessingSubmit(false); // Reset processing flag regardless of success or failure
              }
            }
          }}
        >
          <Grid
            container
            direction="row"
            spacing={1}
            marginTop={1}
            marginBottom={1}
            justifyContent="space-between"
          >
            {clipOptionsFromStoryBlok.options.length > 0 &&
              !twynReplyInProgress && (
                <Grid item xs={5}>
                  <Typography variant="body1">
                    &nbsp;&nbsp;&nbsp;
                    {clipOptionsFromStoryBlok.description.toUpperCase()}
                  </Typography>
                </Grid>
              )}
          </Grid>
          <Grid
            container
            direction="row"
            spacing={2}
            marginTop={conversationInitiated ? 1 : 0}
            marginBottom={isProduction && conversationInitiated ? 2 : 0}
            justifyContent="space-between"
          >
            {clipOptionsFromStoryBlok.options.length === 0 && (
              <>
                <Grid item xs>
                  <TextField
                    autoFocus
                    fullWidth
                    id="userInput"
                    variant="outlined"
                    label="Reply"
                    value={transcript}
                    disabled={processingSubmit}
                    onChange={(e) => setTranscript(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="send_reply_button"
                            disabled={
                              processingSubmit || transcript.trim().length === 0
                            }
                            type="submit"
                          >
                            {transcript.trim().length > 0 ? (
                              <SendIconActive />
                            ) : (
                              <SendIconInactive />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            {clipOptionsFromStoryBlok.options.length > 0 &&
              !twynReplyInProgress &&
              clipOptionsFromStoryBlok.options.map((opt, index) => (
                <Grid item xs={3}>
                  <Fab
                    variant="extended"
                    id={`option_${index}_${opt.clipLabel}`}
                    size="medium"
                    color="primary"
                    onClick={() =>
                      dispatch(
                        handleClipOptionClicked(
                          opt.clipLabel,
                          opt.title,
                          currentEpisode,
                        ),
                      )
                    }
                  >
                    {opt.title.toUpperCase()}
                  </Fab>
                </Grid>
              ))}
          </Grid>
        </form>
      )}
      {conversationInitiated && isEndOfConversationClip && (
        <>
          <Alert severity="success" sx={{ marginTop: 20, marginBottom: 2 }}>
            <AlertTitle>You have completed the conversation</AlertTitle>
            {isProduction && (
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Typography variant="body1" gutterBottom>
                    <strong>
                      If you want to chat again, hit restart below
                    </strong>
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!isProduction && (
              <strong>
                If you want to chat again, select clip(optional) and click the
                &lsquo;Start&lsquo; button
              </strong>
            )}
          </Alert>

          {isProduction && (
            <>
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{ marginLeft: 5 }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    id="restart_button"
                    color="primary"
                    onClick={() => {
                      setIsEmailValid(false);
                      setHasEnteredEmail(false);
                      setDidEnterBadEmail(false);
                      dispatch(start(currentEpisode, currentEpisodePrompts));
                    }}
                  >
                    <strong>RESTART</strong>
                  </Button>
                </Grid>
              </Grid>
              <form
                onSubmit={async (event) => {
                  if (validateEmail(email)) {
                    setIsEmailValid(true);
                    setHasEnteredEmail(true);
                    logEmail(email);
                  } else {
                    setDidEnterBadEmail(true);
                  }
                  setEmail('');
                  event.preventDefault();
                }}
              >
                <Grid item xs marginTop={3} marginX={5} paddingY={2}>
                  <TextField
                    autoFocus
                    fullWidth
                    id="userEmailInput"
                    variant="outlined"
                    label="Enter your email here"
                    error={didEnterBadEmail && !hasEnteredEmail}
                    disabled={hasEnteredEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="send_reply_button"
                            disabled={email.trim().length === 0}
                            type="submit"
                          >
                            {email.trim().length > 0 ? (
                              <SendIconActive />
                            ) : (
                              <SendIconInactive />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {didEnterBadEmail && !hasEnteredEmail && (
                    <p>Please enter a valid email</p>
                  )}
                  {hasEnteredEmail && (
                    <p>Thanks, we&apos;ll be in touch soon</p>
                  )}
                </Grid>
              </form>
            </>
          )}
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  conversationUUID: state.conversationSliceForExternalChat.conversationUUID,
  conversationInitiated:
    state.conversationSliceForExternalChat.conversationInitiated,
  currentClip: state.conversationSliceForExternalChat.currentClip,
  currentEpisode: state.conversationSliceForExternalChat.currentEpisode,
  currentEpisodePrompts:
    state.conversationSliceForExternalChat.currentEpisodePrompts,
  twynReplyInProgress: state.conversationSliceForExternalChat.isTwynReplying,
});

export default connect(mapStateToProps)(ReplyForExternalChat);
