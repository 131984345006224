/* eslint no-param-reassign: "error" */
import { useDispatch } from 'react-redux';
import {
  CircularProgress,
  Chip,
  Grid,
  Alert,
  AlertTitle,
  Fab,
  Typography,
  AppBar,
  Toolbar,
  Paper,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import UtterancesForExternalChat from './UtterancesForExternalChat';
import { start } from './conversationSliceForExternalChat';
import { fetchEpisodePrompts, fetchEpisodes } from './ExternalChatService';
import ReplyForExternalChat from './ReplyForExternalChat';
import { defaultEpisodePrompts, EpisodePrompts } from './schema/EpisodePrompts';
import { hasContent } from '../../../HelperFunctions/strings';
import {
  defaultEpisode,
  Episode,
  getStartClipLabelBasedOnEpisodeVisits,
} from './schema/Episode';
import { isProduction } from '../../../config';
import {
  getEpisodeVisitsCount,
  resetEpisodeVisitsCount,
} from '../../../HelperFunctions/BrowserLocalStorage/LocalStorageService';

export default function ConversationForExternalChat({
  twynId,
  episodeId,
  startClip,
  version,
}: {
  twynId: string | null | undefined;
  episodeId: string | null | undefined;
  startClip: string | null | undefined;
  version: string | null | undefined;
}) {
  const dispatch = useDispatch();
  const [currentEpisode, setCurrentEpisode] = useState<Episode>(defaultEpisode);
  const [currentEpisodePrompts, setCurrentEpisodePrompts] =
    useState<EpisodePrompts>(defaultEpisodePrompts);
  const [clipTitles, setClipTitles] = useState(['']);
  const [selectedClipLabel, setSelectedClipLabel] = useState('');
  const [hasChatData, setHasChatData] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [conversationCommenced, setConversationCommenced] = useState(false);
  const [episodeStartClip, setEpisodeStartClip] = useState(startClip);

  useEffect(() => {
    (async () => {
      try {
        if (!twynId || !episodeId) {
          setErrorMessage('Invalid Parameter');
          return;
        }

        const episodes = await fetchEpisodes(twynId, version);
        const ce = episodes.find((ep) => ep.episode_id === episodeId);
        const currentStartClip = ce?.start_clip;
        if (currentStartClip) {
          setEpisodeStartClip(currentStartClip);
        }
        const episodePrompts = await fetchEpisodePrompts(twynId, version);
        const cePrompts = episodePrompts.get(episodeId);

        if (ce && cePrompts) {
          setCurrentEpisode(ce);
          setCurrentEpisodePrompts(cePrompts);
          if (ce.varyEntryPoints && ce.varyEntryPoints.length === 0)
            resetEpisodeVisitsCount(ce.episode_id);
          const allClips = ce.clips;
          const keys = Object.keys(allClips).filter((key) => key !== 'GLOBAL');
          const names = keys.filter(
            (key) =>
              allClips[key]?.next?.length > 0 ||
              (allClips[key]?.next.length === 0 && allClips[key]?.options),
          );
          setClipTitles(names);
          setConversationCommenced(true);
          dispatch(
            start(
              ce,
              cePrompts,
              selectedClipLabel !== ''
                ? selectedClipLabel
                : episodeStartClip ?? '',
            ),
          );
          sendMessageToParent();
        }
        setHasChatData(cePrompts !== null && cePrompts !== undefined);
      } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [twynId, episodeId, dispatch]);

  const sendMessageToParent = () => {
    console.log('send message');
    window.parent.postMessage('buttonClicked', '*');
  };

  return (
    <>
      {hasContent(errorMessage) && (
        <Alert severity="error">
          <AlertTitle>Error occurred</AlertTitle>
          {errorMessage}
        </Alert>
      )}
      {loading && (
        <>
          <Grid container direction="column" alignItems="center">
            <CircularProgress />
          </Grid>
        </>
      )}
      {!loading &&
        errorMessage === '' &&
        (clipTitles.length <= 0 || !hasChatData) && (
          <Chip
            label="No Prompts Data Found For This Episode"
            variant="filled"
            color="error"
          />
        )}
      {!loading && clipTitles.length > 0 && hasChatData && (
        <>
          <Paper id="chatMessagesArea" square>
            {!conversationCommenced && (
              <Grid item xs={2}>
                <Fab
                  sx={{ marginTop: isMobile ? 15 : 0 }}
                  variant="extended"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    setConversationCommenced(true);
                    dispatch(
                      start(
                        currentEpisode,
                        currentEpisodePrompts,
                        selectedClipLabel !== ''
                          ? selectedClipLabel
                          : episodeStartClip ?? '',
                      ),
                    );
                    sendMessageToParent();
                  }}
                >
                  <Typography variant="body2">
                    &nbsp;&nbsp;&nbsp;Tap to reply&nbsp;&nbsp;&nbsp;
                  </Typography>
                </Fab>
              </Grid>
            )}
            {!isProduction && (
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <Typography>{episodeId}</Typography>
                </Grid>
              </Grid>
            )}
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <UtterancesForExternalChat />
              </Grid>
            </Grid>
          </Paper>
          {conversationCommenced && (
            <AppBar
              position="sticky"
              sx={{
                height: '110px',
                top: 'auto',
                bottom: 0,
                background: 'black',
                marginTop: 2,
              }}
            >
              <Toolbar disableGutters>
                <Grid container direction="column">
                  <ReplyForExternalChat />
                  {!isProduction && (
                    <Grid container direction="row" spacing={2} marginTop={1}>
                      <Grid item xs={5}>
                        <Autocomplete
                          disablePortal
                          size="small"
                          onInputChange={(event, newInputValue) => {
                            setSelectedClipLabel(newInputValue);
                          }}
                          id="start_clip_select"
                          options={clipTitles}
                          defaultValue={
                            episodeStartClip ??
                            getStartClipLabelBasedOnEpisodeVisits(
                              currentEpisode,
                              getEpisodeVisitsCount(currentEpisode.episode_id),
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <Fab
                          variant="extended"
                          id="start_button"
                          size="medium"
                          color="primary"
                          onClick={() => {
                            setConversationCommenced(true);
                            dispatch(
                              start(
                                currentEpisode,
                                currentEpisodePrompts,
                                selectedClipLabel !== ''
                                  ? selectedClipLabel
                                  : episodeStartClip ?? '',
                              ),
                            );
                          }}
                        >
                          <Typography variant="body2">
                            &nbsp;&nbsp;&nbsp;START&nbsp;&nbsp;&nbsp;
                          </Typography>
                        </Fab>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Toolbar>
            </AppBar>
          )}
        </>
      )}
    </>
  );
}
