import React from 'react';
import { Box, Container, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import history from './history';
import Home from './Home';
import store from './store';
import { theme } from './theme';

export default function RootApp() {
  const appTheme = React.useMemo(() => theme, []);
  return (
    <>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <CmsApplication />
      </ThemeProvider>
    </>
  );
}

const CmsApplication = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route>
            <Container maxWidth="lg">
              <Provider store={store}>
                <Box
                  sx={{
                    padding: 2,
                    width: '100%',
                  }}
                >
                  <Route path="/">
                    <Home />
                  </Route>
                </Box>
              </Provider>
            </Container>
          </Route>
        </Switch>
      </Router>
    </>
  );
};
