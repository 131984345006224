/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { DateTime } from 'luxon';
import { v4 as uuid } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../store';
import { getLLMResponse, getTextGenerationLLMResponse } from './ExternalChatService';
import {
  defaultEpisode,
  defaultEpisodeClip,
  Episode,
  EpisodeClip,
  getStartClipLabelBasedOnEpisodeVisits,
} from './schema/Episode';
import { defaultEpisodePrompts, EpisodePrompts } from './schema/EpisodePrompts';
import { logAnalytics, logErrors } from '../../../analytics';
import { UtteranceBubbleColour } from './schema/Utterance';
import { hasContent } from '../../../HelperFunctions/strings';
import {
  getEpisodeVisitsCount,
  setEpisodeVisitsCount,
} from '../../../HelperFunctions/BrowserLocalStorage/LocalStorageService';
import { parameterLookupArray, convHistory, setHistory, emptyHistory, addToJumpArray, jumpFlagArray, emptyJumps} from '../../../config';


let jumpPoint = {jumpFrom: '', jumpTo: ''};
let oldClipLabel = '';
let currentEpisodePrompts = defaultEpisodePrompts;

export type Trigger = Record<string, never | string>;

export interface ConnectionsForExternalChat {
  auto?: { trigger: Trigger };
}

export interface UtteranceForExternalChat {
  id: string;
  speaker: string;
  transcript: string;
  videoUrl?: string;
  clipLabel?: string;
  connections: ConnectionsForExternalChat;
}

export interface CurrentClip {
  clipLabel: string;
  clip: EpisodeClip;
}

const defaultCurrentClip: CurrentClip = {
  clipLabel: '',
  clip: defaultEpisodeClip,
};
interface ConversationSliceStateForExternalChat {
  utterances: UtteranceForExternalChat[];
  connections: ConnectionsForExternalChat;
  conversationInitiated: boolean;
  isEndOfConversationClip: boolean;
  currentClip: CurrentClip;
  currentEpisode: Episode;
  currentEpisodePrompts: EpisodePrompts;
  utteranceBubbleColours: Record<string, UtteranceBubbleColour>;
  conversationUUID: string;
  isTwynReplying: boolean;
  currentUtteranceSpeakerName: string;
  currentEpisodeDefaultIdleClipLabel: string;
}

interface ConversationMetadata {
  currentEpisode: Episode;
  currentEpisodePrompts: EpisodePrompts;
  utteranceBubbleColours: Record<string, UtteranceBubbleColour>;
  conversationUUID: string;
  currentEpisodeDefaultIdleClipLabel: string;
}

const initialState: ConversationSliceStateForExternalChat = {
  connections: {},
  currentClip: defaultCurrentClip,
  conversationInitiated: false,
  isEndOfConversationClip: false,
  currentEpisode: defaultEpisode,
  currentEpisodePrompts: defaultEpisodePrompts,
  utterances: [],
  utteranceBubbleColours: {},
  conversationUUID: '',
  isTwynReplying: false,
  currentUtteranceSpeakerName: '',
  currentEpisodeDefaultIdleClipLabel: '',
};

export const conversationSliceForExternalChat = createSlice({
  name: 'conversationForExternalChat',
  initialState,
  reducers: {
    updateIsTwynReplying: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isTwynReplying: action.payload,
      };
    },
    updateConversationInitiated: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        conversationInitiated: action.payload,
      };
    },
    updateCurrentUtteranceSpeakerName: (
      state,
      action: PayloadAction<string>,
    ) => {
      return {
        ...state,
        currentUtteranceSpeakerName: action.payload,
      };
    },
    updateCurrentClip: (state, action: PayloadAction<CurrentClip>) => {
      return {
        ...state,
        currentClip: action.payload,
      };
    },
    updateConversationMetadata: (
      state,
      action: PayloadAction<ConversationMetadata>,
    ) => {
      return {
        ...state,
        currentEpisode: action.payload.currentEpisode,
        currentEpisodePrompts: action.payload.currentEpisodePrompts,
        utteranceBubbleColours: action.payload.utteranceBubbleColours,
        conversationUUID: action.payload.conversationUUID,
        currentEpisodeDefaultIdleClipLabel: action.payload.currentEpisodeDefaultIdleClipLabel
      };
    },

    resetState: (state) => ({
      ...state,
      utterances: [],
      conversationInitiated: false,
      connections: {},
      currentClip: defaultCurrentClip,
      isEndOfConversationClip: false,
      currentEpisode: defaultEpisode,
      currentEpisodePrompts: defaultEpisodePrompts,
      utteranceBubbleColours: {},
      conversationUUID: '',
      currentUtteranceSpeakerName: '',
    }),
    addUtterance: (state, action: PayloadAction<UtteranceForExternalChat>) => {
      // eslint-disable-next-line no-param-reassign
      action.payload.transcript = action.payload.transcript
        .replace(/[\r\t\n]/g, ' ')
        .replace(/\s+/g, ' ');
      return {
        ...state,
        utterances: [...state.utterances, action.payload],
        connections: action.payload.connections,
      };
    },
  },
});

export const {
  addUtterance,
  updateCurrentUtteranceSpeakerName,
  resetState,
  updateConversationMetadata,
  updateCurrentClip,
  updateIsTwynReplying,
  updateConversationInitiated,
} = conversationSliceForExternalChat.actions;

export function start(
  currEpisode: Episode,
  currEpisodePrompts: EpisodePrompts,
  selectedStartClipLabel?: string,
) {
  emptyHistory();
  emptyJumps();
  return async (dispatch: AppDispatch) => {
    dispatch(resetState());
    dispatch(updateConversationInitiated(true));
    const startClipLabel =
      selectedStartClipLabel && hasContent(selectedStartClipLabel)
        ? selectedStartClipLabel
        : getStartClipLabelBasedOnEpisodeVisits(
          currEpisode,
          getEpisodeVisitsCount(currEpisode.episode_id),
        );
    const resource: EpisodeClip = currEpisode.clips[startClipLabel];
    const clipTrigger = getEpisodeClipAutoOrSingleTrigger(resource);
    const utteranceBubbleColours = getAllUniqueClipSpeakerColours(currEpisode);
    const currentEpisodeDefaultIdleClipLabel = Object.keys(currEpisode.clips).find((key) => currEpisode.clips[key]
      .entry_triggers?.findIndex(tr => tr.Trigger.toLowerCase() === 'idle') !== -1) ?? '';
    const conversationUUID = uuid();
    dispatch(updateCurrentClip({ clipLabel: startClipLabel, clip: resource }));
    dispatch(
      updateConversationMetadata({
        currentEpisode: currEpisode,
        currentEpisodePrompts: currEpisodePrompts,
        utteranceBubbleColours,
        conversationUUID,
        currentEpisodeDefaultIdleClipLabel,
      }),
    );
    scroll()
    dispatch(
      addUtterance({
        id: uuid(),
        speaker: getSpeakerNameFromIdleClipLabel(
          resource.idle_clips[0]?.clipLabel ?? currentEpisodeDefaultIdleClipLabel,
        ),
        transcript: resource.transcript,
        videoUrl: resource.active_video?.video_url,
        clipLabel: startClipLabel,
        connections:
          clipTrigger && isAutoTrigger(clipTrigger)
            ? { auto: { trigger: clipTrigger } }
            : {},
      }),
    );
    if (currEpisode.varyEntryPoints?.length > 0) {
      setEpisodeVisitsCount(currEpisode.episode_id);
    }
    setHistory(startClipLabel, "");
    console.log(convHistory);
    dispatch(checkForAndHandleSingleNextTrigger(startClipLabel, currEpisode));
  };
}

async function checkHistory(clipLabel: string, currentTranscript: string){
  const promptString = 'Rephrase the provided text. Make sure to keep an informal, but still respectful tone similar to the provided text. Provided Text:  '
  if(convHistory.some(e => e.clipLabel === clipLabel)) {
    const newTranscript = await getTextGenerationLLMResponse(currentTranscript, promptString);
    return newTranscript;
  }
  return currentTranscript;
}

async function handleResponse(
  continuationClip: EpisodeClip,
  clipLabel: string,
  episode: Episode,
  dispatch: AppDispatch,
  jumpTranscript:string,
  additionalUtteranceForRepeat?: string,
) {
  let clipTranscript = continuationClip.transcript;
  
  if(jumpTranscript !== '' && jumpTranscript !== undefined){
    clipTranscript = jumpTranscript!;
  }

  clipTranscript = await checkHistory(clipLabel, clipTranscript);
  let continuationClipSpeaker = continuationClip.idle_clips[0]?.clipLabel;
  if (!continuationClipSpeaker) {
    continuationClipSpeaker = Object.keys(episode.clips).find((key) => episode.clips[key]
      .entry_triggers?.findIndex(tr => tr.Trigger.toLowerCase() === 'idle') !== -1) ?? '';
  }
  const speakerName = getSpeakerNameFromIdleClipLabel(continuationClipSpeaker);
  dispatch(updateCurrentUtteranceSpeakerName(speakerName));
  dispatch(updateIsTwynReplying(true));
  scroll()
  await delay(2500);
  dispatch(updateIsTwynReplying(false));
  dispatch(updateCurrentUtteranceSpeakerName(''));
  const clipTrigger = getEpisodeClipAutoOrSingleTrigger(continuationClip);

  dispatch(
    addUtterance({
      id: uuid(),
      speaker: speakerName,
      videoUrl: continuationClip.active_video?.video_url,
      transcript: (additionalUtteranceForRepeat ?? '') + clipTranscript ?? '',
      clipLabel,
      connections:
        clipTrigger && isAutoTrigger(clipTrigger)
          ? { auto: { trigger: clipTrigger } }
          : {},
    }),
  );
  dispatch(updateCurrentClip({ clipLabel, clip: continuationClip }));
  scroll()
  await delay(400);
  scroll()
  dispatch(checkForAndHandleSingleNextTrigger(clipLabel, episode));
  
}

function getNavigationStatus(
  episodeId: string,
  episodeConfig: Record<string,string>[]
){
  const episodeJson = episodeConfig.find((obj) => obj.episodeId === episodeId);
  return episodeJson?.nonLinearNav;
};

function checkClipBubbleStatus(clipLabel: string, episodePrompts: any){
  if(jumpFlagArray.length > 0){
    const jumpArrayCopy = [...jumpFlagArray];
    const currentJumpObj = jumpArrayCopy.shift()!.jumpTo;
    const exitArray = episodePrompts.NON_LINEAR_NAVIGATION[currentJumpObj].exit_list;
    if(exitArray.includes(clipLabel)){
      return jumpFlagArray.shift();
    }
  }
  return {jumpFrom:'', jumpTo: ''};
};

function checkJumpPoint(currClipLabel:string, episodePrompts:EpisodePrompts){
  let jumpTranscript = '';
  let clipLabel = currClipLabel;
  
  
    jumpPoint = checkClipBubbleStatus(clipLabel, episodePrompts)!;
    
    if(jumpPoint?.jumpFrom !== '' && jumpPoint !== undefined){
      oldClipLabel = clipLabel;
      clipLabel = episodePrompts.NON_LINEAR_NAVIGATION[jumpPoint.jumpTo].exit_prompt;
      jumpTranscript = episodePrompts.NON_LINEAR_NAVIGATION[jumpPoint.jumpTo].handler_transcript;
      
      return {clipLabel, jumpTranscript}
    }
    
    return {clipLabel, jumpTranscript}
    
}

export function reply(
  conversationUUID: string,
  userInputTranscript: string,
  currentClipLabel: string,
  episode: Episode,
  episodePrompts: EpisodePrompts,
) {
  let clipLabel = currentClipLabel;
  currentEpisodePrompts = episodePrompts; 
  return async (dispatch: AppDispatch) => {
    dispatch(updateIsTwynReplying(true));
    scroll()
    dispatch(
      addUtterance({
        id: uuid(),
        speaker: 'User',
        transcript: userInputTranscript,
        connections: {},
      }),
    );
    
    const response = await getLLMResponse(
      userInputTranscript,
      clipLabel,
      episodePrompts,
    );
    const responseToken = response;
    const promptStart = episodePrompts.context[clipLabel].prompt_start;
    const promptEnd = episodePrompts.context[clipLabel].prompt_end;
    const prompt = `${promptStart} ${userInputTranscript} ${promptEnd} `;
    let additionalUtteranceForRepeat: string | undefined;
    if (episodePrompts.continuation_mappings[clipLabel][responseToken] === undefined) {
      const telemetryData: Record<string, string> = {
        UserUtterance: userInputTranscript,
        PromptData: prompt,
        ResponseToken: responseToken,
      }
      logErrors("Twyn Chat For Externals - Response Token Error", telemetryData);
      additionalUtteranceForRepeat = "Sorry I didn't get that, I'll ask the question again. "
    }
    let continuationTrigger =
      responseToken === '0'
        ? 'RUDE'
        : episodePrompts.continuation_mappings[clipLabel][responseToken];

    
    if (continuationTrigger === 'LAST_POSITION'){ 
      continuationTrigger=jumpPoint.jumpFrom;
      clipLabel=jumpPoint.jumpFrom;
    } else if (continuationTrigger === 'CURRENT_POSITION'){
      continuationTrigger=oldClipLabel;
      clipLabel=oldClipLabel;
    }
    
    if (continuationTrigger === 'NO_MATCH' && getNavigationStatus(episode.episode_id, parameterLookupArray) && jumpFlagArray.length < 1){

      
      const nmClipLabel = "NM_EXTENSION";
      const nmResponse = await getLLMResponse(
        userInputTranscript,
        nmClipLabel,
        episodePrompts,
      );
    
      const nmResponseToken = nmResponse;
      const nmContinuationTrigger =
      nmResponseToken === '0'
        ? 'RUDE'
        : episodePrompts.continuation_mappings[nmClipLabel][nmResponseToken];

      if (nmContinuationTrigger !== 'NO_MATCH'){
        continuationTrigger = nmContinuationTrigger;
        addToJumpArray(clipLabel,nmContinuationTrigger);
        clipLabel = nmClipLabel;
      }
      
    }


    let continuationClipLabel =
      episode.clips[clipLabel].next.find(
        (nc) => nc.trigger === continuationTrigger,
      )?.name ?? clipLabel;
    const continuationClip = episode.clips[continuationClipLabel];
    
    

    const jumpObject = checkJumpPoint(continuationClipLabel, episodePrompts);
    continuationClipLabel = jumpObject.clipLabel;
    const {jumpTranscript} = jumpObject;
    
    const timestamp = DateTime.utc();
    const telemetryData: Record<string, string> = {
      uuid: conversationUUID,
      timestamp: timestamp.toISO(),
      timestampUnixMs: timestamp.toMillis().toString(),
      previousClipLabel: clipLabel,
      nextClipLabel: continuationClipLabel,
      userUtterance: userInputTranscript,
      PromptData: prompt,
      ResponseToken: responseToken,
      inputCategory: continuationTrigger,
      episodeName: episode.episode_id
    };

    logAnalytics(telemetryData);
    
    await handleResponse(
      continuationClip,
      continuationClipLabel,
      episode,
      dispatch,
      jumpTranscript,
      additionalUtteranceForRepeat
    );
    setHistory(continuationClipLabel, userInputTranscript);
    
  };
}

export function continueWithTrigger(
  trigger: Trigger,
  currentClipLabel: string,
  episode: Episode,
) {
  return async (dispatch: AppDispatch) => {
    let continuationClipLabel =
      episode.clips[currentClipLabel].next.find(
        (nc) => nc.trigger === trigger.trigger,
      )?.name ?? currentClipLabel;
    const continuationClip = episode.clips[continuationClipLabel];
    let jumpTranscript = '';
    const jumpObject = checkJumpPoint(continuationClipLabel, currentEpisodePrompts);
    continuationClipLabel = jumpObject.clipLabel;
    jumpTranscript = jumpObject.jumpTranscript;
    

    await handleResponse(
      continuationClip,
      continuationClipLabel,
      episode,
      dispatch,
      jumpTranscript,
    );
    
    setHistory(continuationClipLabel, "AUTO");
    
  };
  
}

export function handleClipOptionClicked(
  optionClipLabel: string,
  optionTitle: string,
  episode: Episode,
) {
  let optClipLabel = optionClipLabel;
  return async (dispatch: AppDispatch) => {
    const optionClip = episode.clips[optionClipLabel];
    dispatch(
      addUtterance({
        id: uuid(),
        speaker: 'User',
        transcript: optionTitle,
        connections: {},
      }),
    );

    const jumpObject = checkJumpPoint(optClipLabel, currentEpisodePrompts);
    optClipLabel = jumpObject.clipLabel;
    const {jumpTranscript} = jumpObject;

    await handleResponse(optionClip, optClipLabel, episode, dispatch, jumpTranscript);
    setHistory(optionClipLabel, "");
    
    
  };
}

function checkForAndHandleSingleNextTrigger(
  clipLabel: string,
  episode: Episode,
) {
  return async (dispatch: AppDispatch) => {
    const clip = episode.clips[clipLabel];

    if (clipHasAutoOrSingleNexTrigger(clip)) {
      const trigger = getEpisodeClipAutoOrSingleTrigger(clip);
      if (trigger) {
        dispatch(continueWithTrigger(trigger, clipLabel, episode));
      }
    }
  };
}

function getEpisodeClipAutoOrSingleTrigger(
  clip: EpisodeClip,
): Record<string, string> | undefined {
  const length = clip.next?.length ?? 0;
  switch (length) {
    case 0:
      return undefined;
    case 1:
      return clip.next?.find((n) => n.type === 'Clip');
    default:
      return clip.next?.find((n) => isAutoTrigger(n));
  }
}

function getAllUniqueClipSpeakerColours(
  episode: Episode,
): Record<string, UtteranceBubbleColour> {
  const twyns: Set<string> = new Set();
  const colours: UtteranceBubbleColour[] = [
    'primary',
    'warning',
    'success',
    'error',
    'info',
  ];
  const utteranceBubbleColoursForTwyns: Record<string, UtteranceBubbleColour> =
    {};
  Object.keys(episode.clips).forEach((clipLabel) => {
    const defaultIdleClip = episode.clips[clipLabel].entry_triggers?.find(
      (entryTrigger) => entryTrigger.Trigger.toLowerCase() === 'idle',
    );
    if (defaultIdleClip) {
      twyns.add(getSpeakerNameFromIdleClipLabel(clipLabel));
    }
    episode.clips[clipLabel].idle_clips?.forEach((idle) =>
      twyns.add(getSpeakerNameFromIdleClipLabel(idle.clipLabel)),
    );
  });
  let counter = 0;
  twyns.forEach((twyn) => {
    utteranceBubbleColoursForTwyns[twyn] = colours[counter];
    counter += 1;
  });
  return utteranceBubbleColoursForTwyns;
}

export function getSpeakerNameFromIdleClipLabel(clipLabel: string): string {
  return clipLabel?.replace('PL_', '').split('_')[0] ?? '';
}

export function getSpeakerNameAndBubbleColourFromAbbreviation(
  abbreviation: string,
): [string, UtteranceBubbleColour] {
  let speakerName = abbreviation;
  let colour: UtteranceBubbleColour = 'primary';
  if (abbreviation)
    switch (abbreviation) {
      case 'User':
        speakerName = 'ME';
        break;
      case 'NAN':
        speakerName = 'NANETTE';
        colour = 'secondary';
        break;
      case 'FLO':
        speakerName = 'FLORENCIA';
        colour = 'success';
        break;
      case 'MESSI':
        speakerName = 'MESSI';
        colour = 'info';
        break;
      case 'HOST':
        speakerName = abbreviation;
        colour = 'success';
        break;
      default:
        speakerName = abbreviation;
        break;
    }
  return [speakerName, colour];
}

function clipHasAutoOrSingleNexTrigger(clip: EpisodeClip): boolean {
  return (
    (clip.next?.length === 1 && clip.next[0].type === 'Clip') ||
    clip.next?.some((n) => isAutoTrigger(n))
  );
}

function isAutoTrigger(clipTrigger: Trigger): boolean {
  return (
    clipTrigger && clipTrigger.trigger === 'AUTO' && clipTrigger.type === 'Clip'
  );
}

function scroll() {
  document.documentElement.scrollTop = document.getElementById("messagesBoundary")?.offsetTop ?? 0;
}

async function delay(ms: number) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((f) => setTimeout(f, ms));
}

export default conversationSliceForExternalChat.reducer;
